.page {
  height: 100%;
	width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page .title {
	font-size: 40px;
}

.page .subtitle {
	font-size: 20px;
	margin-top: -10px;
	margin-bottom: 20px;
}

.page .content {
  display: flex;
  flex-direction: column;
}

.albumArt {
	height: 300px;
	width: 300px;
	overflow: hidden;
}

.guess {
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: black;
}

.select {
	width: 600px;
}

.guessBtn {
	height: 38px;
	width: 80px;
	background-color: white;
	border-radius: 15px;
	margin: 0px 10px 0px 10px;
}