.navbar {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-color);
  border-bottom: 2px solid var(--navbar-color-2);
  box-sizing: content-box;
}

.navbar .navbar-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}

.navbar .content-left {
  width: 100%;
  height: 100%;
  display: inherit;
  justify-content: left;
  align-items: center;
}

.navbar .content-middle {
  width: 100%;
  height: 100%;
  display: inherit;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}

.navbar .content-right {
  width: 100%;
  height: 100%;
  display: inherit;
  justify-content: right;
  align-items: center;
}